import React from "react";
import { FadeIn } from "react-slide-fade-in";
import Partner1 from "../Assets/Img/partner1.png";
import Partner2 from "../Assets/Img/partner2.png";
import Partner3 from "../Assets/Img/partner3.png";
import Partner4 from "../Assets/Img/partner4.png";
import Partner5 from "../Assets/Img/partner5.png";
import Partner6 from "../Assets/Img/partner6.png";
import Partner7 from "../Assets/Img/partner7.png";
import Partner8 from "../Assets/Img/partner8.png";
import Partner9 from "../Assets/Img/partner9.png";
import Partner11 from "../Assets/Img/partner11.png";
import Partner12 from "../Assets/Img/partner12.png";
import Person1 from "../Assets/Img/person1.jpg";
import Person2 from "../Assets/Img/person22.jpg";
import Person3 from "../Assets/Img/person2.jpg";
import Person5 from "../Assets/Img/person5.png";
import Person6 from "../Assets/Img/person6.png";
import Person81 from "../Assets/Img/person81.png";
import Person9 from "../Assets/Img/person9.png";
import Person10 from "../Assets/Img/person10.png";
import Person14 from "../Assets/Img/person14.png";
import Person15 from "../Assets/Img/person15.png";
import Person16 from "../Assets/Img/person16.png";
import Person17 from "../Assets/Img/person17.png";
import Person19 from "../Assets/Img/person19.png";
import Person20 from "../Assets/Img/person20.png";
import Roadmap from "../Assets/Img/roadmap.png";
import RoadmapMobile from "../Assets/Img/roadmap-mobile.png";
import { MdRocketLaunch } from "react-icons/md";
import { FaPeopleGroup } from "react-icons/fa6";
import { FaCheck } from "react-icons/fa";
import { GiGrowth, GiTwoCoins, GiTreeBranch } from "react-icons/gi";
import Team from "../Components/Team";
import Partners from "../Components/Partners";
// import { useTimer } from "../Controller/Constants";
export default function About() {
  // const { days, hours, minutes, seconds } = useTimer();
  return (
    <section>
      <section>
        <div
          className="isolate bg-white hero-about lg:bg-center md:bg-top bg-[url('./Assets/Img/about-bg.jpg')] bg-center"
          style={{ backgroundRepeat: "no-repeat" }}
        >
          <main className="" style={{ maxWidth: "1400px", margin: "0 auto" }}>
            <div className="">
              <div className="p-10 lg:p-20 md:p-20 ">
                <div>
                  <div className="pt-20">
                    <FadeIn
                      from="left"
                      positionOffset={400}
                      triggerOffset={200}
                      delayInMilliseconds={0}
                    >
                      <p className="text-white lg:pb-0 md:pb-0 pb-20 lg:pt-0 md:pb-0 pt-10 text-right hero-heading">
                        who we are.
                      </p>
                    </FadeIn>
                    <FadeIn
                      from="right"
                      positionOffset={400}
                      triggerOffset={200}
                      delayInMilliseconds={0}
                    >
                      <h6 className="hero-list mt-10 text-right text-white hidden md:block lg:block">
                        <span>work.</span>
                        <span> sell.</span>
                        <span> borrow.</span>
                        <span> earn.</span>
                        <span> CONNECT.</span>
                      </h6>
                    </FadeIn>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </section>
      <section
        className="lg:p-20 p-10 grid lg:grid-cols-2 md:grid-cols-2 gap-5"
        style={{ maxWidth: "1400px", margin: "0 auto" }}
      >
        <FadeIn
          from="left"
          positionOffset={400}
          triggerOffset={200}
          delayInMilliseconds={0}
        >
          <div className="pb-10 lg:pr-5 md:pr-5">
            <p className="text-bold">
              FAME is a Regenerative Finance (ReFi) initiative
            </p>
            <p className="text-thin">
              A new approach for solving systemic problems in the food chain and
              regenerating communities around it. FAME aims to move individuals
              from the traditional exploitative model to a decentralized web3
              ecosystem to provide autonomy, financial power, control, fair
              return and due recognition.
            </p>
          </div>
        </FadeIn>
        <FadeIn
          from="right"
          positionOffset={400}
          triggerOffset={200}
          delayInMilliseconds={0}
        >
          <div className="pb-10 lg:pl-5 md:pl-5">
            <p className="text-bold">FAME provides a social marketplace</p>
            <p className="text-thin">
              This empowers farmers, workers and consumers. Through technology
              and financial access the aim is to enable farmers to scale and
              escape from poverty, making them “famous” for their products,
              which become directly accessible to consumers who also benefit
              from better quality, fair-priced products.
            </p>
          </div>
        </FadeIn>
      </section>
      {/* <section
        style={{
          background:
            "linear-gradient(63.54deg, #6E0289 16.59%, #00C3B3 88.13%)",
        }}
      >
        <div
          className="lg:p-20 p-10 grid lg:grid-cols-7 gap-10"
          style={{ maxWidth: "1400px", margin: "0 auto" }}
        >
          <img className="col-span-3" src={Img14} alt="" />
          <div className="col-span-2 pb-10">
            <ProblemBox />
            <p className="text-thin text-white mt-5">
              Developing countries face social marginalization and financial
              exclusion leading to poverty and food insecurity, and together
              with the rest of the world, are hostages of Climate Change effects
              that culminate in the degradation of natural resources, loss of
              biodiversity and scarcity of resources.
            </p>
          </div>
          <div className="col-span-2">
            <SolutionBox />
            <p className="text-thin text-white mt-5 ">
              Showcase services and products to support the African community of
              workers, drivers, farmers and consumers to collaborate without any
              middlemen or intermediaries. Access to advanced financial services
              at scale (lending, borrowing) building a multi-million-people
              “middle class”.
            </p>
          </div>
        </div>
      </section> */}
      {/* <section
        className="lg:p-20 p-10"
        style={{ maxWidth: "1400px", margin: "0 auto" }}
      >
        <p className="text-heading">
          <span className="text-secondary">our</span> achievements
        </p>
        <img className="pt-20 lg:block md:block hidden" src={Roadmap} alt="" />
        <img
          className="pt-20 md:hidden lg:hidden sm:block"
          src={RoadmapMobile}
          alt=""
        />
      </section> */}

      <section
        className="lg:p-20 lg:pb-5 p-10 pb-5"
        style={{ maxWidth: "1400px", margin: "0 auto" }}
      >
        <p className="text-heading">
          <span className="text-secondary">our</span> achievements
        </p>
        <div className="mt-5 text-thin">
          <div className="w-2 h-14 bg-primary ml-5 rounded-t-2xl"></div>
          {/* Q2 2023 */}
          <div className="flex space-between">
            <div className="relative ">
              <div className="w-2 h-full bg-primary ml-5 "></div>
              <div className="absolute bg-secondary rounded-3xl w-12 h-12 -top-2 flex justify-center items-center">
                <FaCheck className="text-white w-6 h-6" />
              </div>
            </div>

            <div className="lg:grid grid-cols-10 gap-10">
              <div className="flex flex-col space-y-2 text-left ml-20 max-w-[500px] col-span-4">
                <p className="font-bold">Q2 2023</p>
                <p>Community Drive</p>
              </div>
              <div className="flex flex-col space-y-4 text-left col-span-6 mt-5 lg:mt-0 ml-20 lg:ml-0">
                <div className="inline-flex">
                  <span>
                    <GiTreeBranch className="mr-2 w-4 h-4 mt-2 text-primary" />
                  </span>
                  <p>
                    <span className="font-bold">Social App</span> <br />
                    We established a community-driven platform to foster user
                    engagement on the FAME app.
                  </p>
                </div>
                <div className="inline-flex">
                  <span>
                    <GiTreeBranch className="mr-2 w-4 h-4 mt-2 text-primary" />
                  </span>
                  <p>
                    <span className="font-bold">Marketplace</span> <br />
                    We launched a marketplace to facilitate trade and exchange
                    on FAME app.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* Q1 */}
          <div className="flex space-between">
            <div className="relative">
              <div className="w-2 h-full bg-primary ml-5"></div>
              <div className="absolute bg-secondary rounded-3xl w-12 h-12 top-20 flex justify-center items-center">
                <FaCheck className="text-white w-6 h-6" />
              </div>
            </div>
            <div className="lg:grid grid-cols-10 gap-10 pt-20">
              <div className="flex flex-col space-y-2 text-left ml-20 max-w-[500px] col-span-4">
                <p className="font-bold">Q3 2023 </p>
                <p>Governance and Infrastructure Development</p>
              </div>
              <div className="flex flex-col space-y-4 text-left col-span-6 mt-5 lg:mt-0 ml-20 lg:ml-0">
                {/* <div className="inline-flex">
                  <span>
                    <GiTreeBranch className="mr-2 w-4 h-4 mt-2 text-primary" />
                  </span>
                  <p>
                    <span className="font-bold">NFT Vault/Sales</span> <br />
                    Setting up a secure vault for NFTs and enabling a sales
                    platform.
                  </p>
                </div> */}
                <div className="inline-flex">
                  <span>
                    <GiTreeBranch className="mr-2 w-4 h-4 mt-2 text-primary" />
                  </span>
                  <p>
                    <span className="font-bold">DAO Constitution</span> <br />
                    Formalizing governance through a decentralized autonomous
                    organization.
                  </p>
                </div>
                <div className="inline-flex">
                  <span>
                    <GiTreeBranch className="mr-2 w-4 h-4 mt-2 text-primary" />
                  </span>
                  <p>
                    <span className="font-bold">Treasury Management</span>{" "}
                    <br />
                    We implemented systems for managing the DAO's financial
                    resources.
                  </p>
                </div>
                <div className="inline-flex">
                  <span>
                    <GiTreeBranch className="mr-2 w-4 h-4 mt-2 text-primary" />
                  </span>
                  <p>
                    <span className="font-bold">Referendum</span> <br />
                    We introduced a decentralized voting mechanism for key
                    decisions.
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Q2 */}
          <div className="flex space-between">
            <div className="relative">
              <div className="w-2 h-full bg-primary ml-5"></div>
              <div className="absolute bg-secondary rounded-3xl w-12 h-12 top-20 flex justify-center items-center">
                <FaCheck className="text-white w-6 h-6" />
              </div>
            </div>
            <div className="lg:grid grid-cols-10 gap-10 pt-20">
              <div className="flex flex-col space-y-2 text-left ml-20 max-w-[500px] col-span-4">
                <p className="font-bold">Q4 2023 </p>
                <p>Security and Regional Expansion</p>
              </div>
              <div className="flex flex-col space-y-4 text-left col-span-6 mt-5 lg:mt-0 ml-20 lg:ml-0">
                <div className="inline-flex">
                  <span>
                    <GiTreeBranch className="mr-2 w-4 h-4 mt-2 text-primary" />
                  </span>
                  <p>
                    <span className="font-bold">Audit</span> <br />A
                    comprehensive review to ensure the platform’s security and
                    functionality was done.
                  </p>
                </div>
                <div className="inline-flex">
                  <span>
                    <GiTreeBranch className="mr-2 w-4 h-4 mt-2 text-primary" />
                  </span>
                  <p>
                    <span className="font-bold">FAME Hub Nigeria</span> <br />
                    We expanded the project’s presence in Africa with new hubs
                    in Nigeria - Enugu Plateau Farmers
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Q1 */}
          <div className="flex space-between">
            <div className="relative">
              <div className="w-2 h-full bg-primary ml-5 "></div>
              <div className="absolute bg-secondary rounded-3xl w-12 h-12 top-20 flex justify-center items-center">
                <FaCheck className="text-white w-6 h-6" />
              </div>
            </div>
            <div className="lg:grid grid-cols-10 gap-10 pt-20">
              <div className="flex flex-col space-y-2 text-left ml-20 max-w-[500px] col-span-4">
                <p className="font-bold">Q1 2024 </p>
                <p>Financial Services Rollout</p>
              </div>
              <div className="flex flex-col space-y-4 text-left col-span-6 mt-5 lg:mt-0 ml-20 lg:ml-0 ">
                <div className="inline-flex">
                  <span>
                    <GiTreeBranch className="mr-2 w-4 h-4 mt-2 text-primary" />
                  </span>
                  <p>
                    <span className="font-bold">Swap</span> <br />
                    We intoduced the swap feature to enable seamless exchange of
                    assets.
                  </p>
                </div>
                <div className="inline-flex">
                  <span>
                    <GiTreeBranch className="mr-2 w-4 h-4 mt-2 text-primary" />
                  </span>
                  <p>
                    <span className="font-bold">Staking</span> <br />
                    We launched USDC staking earing ~6% APR
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Q2 */}
          <div className="flex space-between">
            <div className="relative">
              <div className="w-2 h-full bg-primary ml-5 "></div>
              <div className="absolute bg-secondary rounded-3xl w-12 h-12 top-20 flex justify-center items-center">
                <FaCheck className="text-white w-6 h-6" />
              </div>
            </div>
            <div className="lg:grid grid-cols-10 gap-10 pt-20">
              <div className="flex flex-col space-y-2 text-left ml-20 max-w-[500px] col-span-4">
                <p className="font-bold">Q2 2024 </p>
                <p>Local Empowerment and Smart Business</p>
              </div>
              <div className="flex flex-col space-y-4 text-left col-span-6 mt-5 lg:mt-0 ml-20 lg:ml-0 ">
                <div className="inline-flex">
                  <span>
                    <GiTreeBranch className="mr-2 w-4 h-4 mt-2 text-primary" />
                  </span>
                  <p>
                    <span className="font-bold">Local DAOs</span> <br />
                    We established decentralized organizations at a local level
                    to empower communities.
                  </p>
                </div>
                <div className="inline-flex">
                  <span>
                    <GiTreeBranch className="mr-2 w-4 h-4 mt-2 text-primary" />
                  </span>
                  <p>
                    <span className="font-bold">Smart Biz Database</span> <br />
                    We created a database tailored for smart business solutions.
                  </p>
                </div>
                <div className="inline-flex">
                  <span>
                    <GiTreeBranch className="mr-2 w-4 h-4 mt-2 text-primary" />
                  </span>
                  <p>
                    <span className="font-bold">AI B2B Matching Agent</span>{" "}
                    <br />
                    Development of an AI-based system to match businesses for
                    partnerships and collaborations started.
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Q3 */}
          <div className="flex space-between">
            <div className="relative">
              <div className="w-2 h-full bg-primary ml-5 "></div>
              <div className="absolute bg-secondary rounded-3xl w-12 h-12 top-20 flex justify-center items-center">
                <FaCheck className="text-white w-6 h-6" />
              </div>
            </div>
            <div className="lg:grid grid-cols-10 gap-10 pt-20 w-full">
              <div className="flex flex-col space-y-2 text-left ml-20 max-w-[500px] col-span-4">
                <p className="font-bold">Q3 2024 </p>
                <p>Full decentralization of Wallets</p>
              </div>
              <div className="flex flex-col space-y-4 text-left col-span-6 mt-5 lg:mt-0 ml-20 lg:ml-0 pb-14">
                <div className="inline-flex">
                  <span>
                    <GiTreeBranch className="mr-2 w-4 h-4 mt-2 text-primary" />
                  </span>
                  <p>
                    <span className="font-bold">New Functionalities</span>
                    <br />
                    Offers, Demands
                  </p>
                </div>
                <div className="inline-flex">
                  <span>
                    <GiTreeBranch className="mr-2 w-4 h-4 mt-2 text-primary" />
                  </span>
                  <p>
                    <span className="font-bold">
                      Village Crédit Agricole Incubator
                    </span>
                    <br />
                    {/* We created a database tailored for smart business solutions. */}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="lg:p-20 lg:pt-0 p-10 pt-0"
        style={{ maxWidth: "1400px", margin: "0 auto" }}
      >
        <p className="text-heading">
          <span className="text-secondary">roadmap</span> project development
        </p>

        <div className="mt-5 text-thin">
          <div className="w-2 h-14 bg-primary ml-5"></div>
          {/* Q4 2024 */}
          <div className="flex space-between">
            <div className="relative ">
              <div className="w-2 h-full bg-primary ml-5"></div>
              <div className="absolute bg-primary rounded-3xl w-12 h-12 -top-2 flex justify-center items-center animate-ping "></div>
              <div className="absolute bg-secondary rounded-3xl w-12 h-12 -top-2 flex justify-center items-center">
                <MdRocketLaunch className="text-white w-6 h-6" />
              </div>
            </div>

            <div className="lg:grid grid-cols-10 gap-10">
              <div className="flex flex-col space-y-2 text-left ml-20 max-w-[500px] col-span-4">
                <p className="font-bold">Q4 2024</p>
                <p>Launch of Stablecoin Financial Services</p>
              </div>
              <div className="flex flex-col space-y-4 text-left col-span-6 mt-5 lg:mt-0 ml-20 lg:ml-0">
                <div className="inline-flex">
                  <span>
                    <GiTreeBranch className="mr-2 w-4 h-4 mt-2 text-primary" />
                  </span>
                  <p>
                    <span className="font-bold">Lending</span> <br />
                    Introduce stablecoin lending services, enabling users to
                    lend their assets for interest.
                  </p>
                </div>
                <div className="inline-flex">
                  <span>
                    <GiTreeBranch className="mr-2 w-4 h-4 mt-2 text-primary" />
                  </span>
                  <p>
                    <span className="font-bold">Borrowing</span> <br />
                    Offer stablecoin borrowing options, providing access to
                    capital for users in need.
                  </p>
                </div>
                <div className="inline-flex">
                  <span>
                    <GiTreeBranch className="mr-2 w-4 h-4 mt-2 text-primary" />
                  </span>
                  <p>
                    <span className="font-bold">Staking of Algo</span> <br />
                    Implement staking services for Algorand (Algo), allowing
                    users to earn rewards by staking their Algo tokens.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* Q1 */}
          <div className="flex space-between">
            <div className="relative">
              <div className="w-2 h-full bg-primary ml-5"></div>
              <div className="absolute bg-secondary rounded-3xl w-12 h-12 top-20 flex justify-center items-center">
                <FaPeopleGroup className="text-white w-6 h-6" />
              </div>
            </div>
            <div className="lg:grid grid-cols-10 gap-10 pt-20">
              <div className="flex flex-col space-y-2 text-left ml-20 max-w-[500px] col-span-4">
                <p className="font-bold">Q1 2025 </p>
                <p>Community and Governance Enhancements</p>
              </div>
              <div className="flex flex-col space-y-4 text-left col-span-6 mt-5 lg:mt-0 ml-20 lg:ml-0">
                <div className="inline-flex">
                  <span>
                    <GiTreeBranch className="mr-2 w-4 h-4 mt-2 text-primary" />
                  </span>
                  <p>
                    <span className="font-bold">
                      Launch DAOs in the FAME App
                    </span>
                    <br />
                    Implement Decentralized Autonomous Organizations (DAOs) to
                    enable community-driven governance and decision-making
                    within the platform.
                  </p>
                </div>
                <div className="inline-flex">
                  <span>
                    <GiTreeBranch className="mr-2 w-4 h-4 mt-2 text-primary" />
                  </span>
                  <p>
                    <span className="font-bold">
                      Stripe / Visa / Mastercard / PayPal APIs for Peer-to-Peer
                      Payments
                    </span>
                    <br />
                    Integrate major payment gateways to facilitate secure and
                    seamless peer-to-peer transactions.
                  </p>
                </div>
                <div className="inline-flex">
                  <span>
                    <GiTreeBranch className="mr-2 w-4 h-4 mt-2 text-primary" />
                  </span>
                  <p>
                    <span className="font-bold">
                      Uber Direct API for Peer-to-Peer Deliveries
                    </span>
                    <br />
                    Incorporate Uber Direct’s API to enable efficient and
                    reliable peer-to-peer delivery services within the FAME
                    ecosystem.
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Q2 */}
          <div className="flex space-between">
            <div className="relative">
              <div className="w-2 h-full bg-primary ml-5"></div>
              <div className="absolute bg-secondary rounded-3xl w-12 h-12 top-20 flex justify-center items-center">
                <GiGrowth className="text-white w-6 h-6" />
              </div>
            </div>
            <div className="lg:grid grid-cols-10 gap-10 pt-20">
              <div className="flex flex-col space-y-2 text-left ml-20 max-w-[500px] col-span-4">
                <p className="font-bold">Q2 2025 </p>
                <p>Development and Launch of Core Platform Enhancements</p>
              </div>
              <div className="flex flex-col space-y-4 text-left col-span-6 mt-5 lg:mt-0 ml-20 lg:ml-0">
                <div className="inline-flex">
                  <span>
                    <GiTreeBranch className="mr-2 w-4 h-4 mt-2 text-primary" />
                  </span>
                  <p>
                    <span className="font-bold">AI Matching</span> <br />
                    Integrate advanced AI matching algorithms to connect users
                    more efficiently, whether it’s matching farmers with buyers,
                    or products with market demand.
                  </p>
                </div>
                <div className="inline-flex">
                  <span>
                    <GiTreeBranch className="mr-2 w-4 h-4 mt-2 text-primary" />
                  </span>
                  <p>
                    <span className="font-bold">Offline Transactions</span>{" "}
                    <br />
                    Develop and launch functionality that allows users to
                    conduct transactions without the need for constant internet
                    access, ensuring broader usability in areas with limited
                    connectivity.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* Q3 */}
          <div className="flex space-between">
            <div className="relative">
              <div className="w-2 h-full bg-primary ml-5 rounded-b-2xl "></div>
              <div className="absolute bg-secondary rounded-3xl w-12 h-12 top-20 flex justify-center items-center">
                <GiTwoCoins className="text-white w-6 h-6" />
              </div>
            </div>
            <div className="lg:grid grid-cols-10 gap-10 pt-20">
              <div className="flex flex-col space-y-2 text-left ml-20 max-w-[500px] col-span-4">
                <p className="font-bold">Q3 2025 </p>
                <p>Expansion of Platform Capabilities</p>
              </div>
              <div className="flex flex-col space-y-4 text-left col-span-6 mt-5 lg:mt-0 ml-20 lg:ml-0">
                <div className="inline-flex">
                  <span>
                    <GiTreeBranch className="mr-2 w-4 h-4 mt-2 text-primary" />
                  </span>
                  <p>
                    <span className="font-bold">B2B Web App</span> <br />
                    Develop a business-to-business (B2B) web application
                    tailored for larger-scale agricultural transactions and
                    enterprise needs.
                  </p>
                </div>
                <div className="inline-flex">
                  <span>
                    <GiTreeBranch className="mr-2 w-4 h-4 mt-2 text-primary" />
                  </span>
                  <p>
                    <span className="font-bold">Supply Chain Management</span>{" "}
                    <br />
                    Introduce comprehensive supply chain management tools to
                    enhance transparency, logistics, and efficiency across the
                    agricultural supply chain.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section
        style={{
          background:
            "linear-gradient(63.54deg, #6E0289 16.59%, #00C3B3 88.13%)",
        }}
      >
        <div style={{ maxWidth: "1400px", margin: "0 auto" }}>
          <div className="grid lg:grid-cols-3  gap-10 p-10 lg:p-20 md:p-20 content-center text-white">
            <div className="text-left">
              <FadeIn
                from="left"
                positionOffset={400}
                triggerOffset={200}
                delayInMilliseconds={0}
              >
                <ProblemBox />
                <p
                  className="mt-5 mb-5 md:mr-20 lg:mr-20 "
                  style={{
                    fontSize: "26px",
                    fontWeight: "600",
                    lineHeight: "33px",
                    letterSpacing: "0.6499999761581421px",
                    textAlign: "left",
                    color: "#595959;",
                  }}
                >
                  As a consumer, Olu can't :
                </p>
                <div className="text-left inline-block mb-3">
                  <span className="red-dot"></span>
                  <span className="text-white mt-0 ">
                    <span className="font-bold">
                      Limited Access to Quality Products:
                    </span>{" "}
                    Olu struggles to find affordable, nutritious, organic, and
                    ethically-sourced products in proximity, risking
                    malnutrition for his family.
                  </span>
                </div>
                <div className="text-left inline-block mb-3">
                  <span className="red-dot"></span>
                  <span className="text-white mt-0 ">
                    <span className="font-bold">Unreliable Local Markets:</span>{" "}
                    The local open markets in Olu's area are limited and don't
                    always guarantee the quality of produce.
                  </span>
                </div>
                <div className="text-left inline-block">
                  <span className="red-dot"></span>
                  <span className="text-white mt-0 ">
                    <span className="font-bold">Financial Exclusion:</span>{" "}
                    Despite his consistent income, Olu's banking services are
                    restrictive, denying him access to microcredits due to his
                    modest earnings.
                  </span>
                </div>
              </FadeIn>
            </div>
            <div className="text-left">
              <FadeIn
                from="left"
                positionOffset={400}
                triggerOffset={200}
                delayInMilliseconds={0}
              >
                <SolutionBox />
                <p
                  className=" mt-5 mb-5 md:mr-20 lg:mr-20"
                  style={{
                    fontSize: "26px",
                    fontWeight: "600",
                    lineHeight: "33px",
                    letterSpacing: "0.6499999761581421px",
                    textAlign: "left",
                  }}
                >
                  With FAME Olu can now:
                </p>

                <div className="text-left inline-block mb-3">
                  <span className="green-dot"></span>
                  <span className="text-white mt-0 ">
                    <span className="font-bold">
                      Direct Connection to Quality Producers:
                    </span>{" "}
                    Through FAME, Olu can directly purchase from local farmers,
                    ensuring he gets fresh, ethical, and organic produce at a
                    fair price.
                  </span>
                </div>
                <div className="text-left inline-block mb-3">
                  <span className="green-dot"></span>
                  <span className="text-white mt-0 ">
                    <span className="font-bold">
                      Strengthening Local Community:
                    </span>{" "}
                    By using FAME, Olu not only ensures better food for his
                    family but also supports and empowers his local community of
                    workers and farmers.
                  </span>
                </div>
                <div className="text-left inline-block mb-3">
                  <span className="green-dot"></span>
                  <span className="text-white mt-0 ">
                    <span className="font-bold">
                      Financial Inclusion with DEFI:
                    </span>{" "}
                    FAME leverages the power of Algorand's DEFI, allowing Olu to
                    earn passive income and access automated borrowing options,
                    promoting financial independence.
                  </span>
                </div>
              </FadeIn>
            </div>
            <div>
              <FadeIn
                from="right"
                positionOffset={400}
                triggerOffset={200}
                delayInMilliseconds={0}
              >
                <img
                  className="content-center"
                  src={Img19}
                  alt=""
                  style={{
                    borderWidth: "9px",
                    borderColor: "#ffffff",
                    borderRadius: "100%",
                  }}
                />
              </FadeIn>
            </div>
          </div>
        </div>
      </section> */}
      {/* <section className="solution content-center">
        <div
          style={{ maxWidth: "1400px", margin: "0 auto" }}
          className="grid lg:grid-cols-3 gap-10 p-10 lg:p-20 md:p-20"
        >
          <div>
            <FadeIn
              from="left"
              positionOffset={400}
              triggerOffset={200}
              delayInMilliseconds={0}
            >
              <img
                className="content-center drop-shadow-[10px_10px_36px_rgba(0,0,0,0.306053)]"
                src={Img1}
                alt=""
                style={{
                  borderWidth: "9px",
                  borderColor: "#ffffff",
                  borderRadius: "100%",
                }}
              />
            </FadeIn>
          </div>
          <div className="text-left">
            <FadeIn
              from="right"
              positionOffset={400}
              triggerOffset={200}
              delayInMilliseconds={0}
            >
              <ProblemBox />
              <p
                className="text-white mt-5 mb-5 md:mr-20 lg:mr-20 solution-heading"
                style={{
                  fontSize: "26px",
                  fontWeight: "600",
                  lineHeight: "33px",
                  letterSpacing: "0.6499999761581421px",
                  textAlign: "left",
                }}
              >
                Emmanuel couldn't find delivery jobs so:
              </p>
              <div className="text-left inline-block mb-3">
                <span className="red-dot"></span>
                <span className="text-white mt-0 solution-text">
                  He relied on friends and family to find new customers.
                </span>
              </div>
              <div className="text-left inline-block mb-3">
                <span className="red-dot"></span>
                <span className="text-white mt-0 solution-text">
                  He waited for hours in front of shops hoping for work.
                </span>
              </div>
              <div className="text-left inline-block">
                <span className="red-dot"></span>
                <span className="text-white mt-0 solution-text">
                  He had no control over his salary or contract and depended on
                  centralized orgs such as Uber.
                </span>
              </div>
            </FadeIn>
          </div>
          <div className="text-left ">
            <FadeIn
              from="right"
              positionOffset={400}
              triggerOffset={200}
              delayInMilliseconds={0}
            >
              <SolutionBox />
              <p
                className="text-white mt-5 mb-5 md:mr-20 lg:mr-20 solution-heading"
                style={{
                  fontSize: "26px",
                  fontWeight: "600",
                  lineHeight: "33px",
                  letterSpacing: "0.6499999761581421px",
                  textAlign: "left",
                }}
              >
                With FAME Emmanuel can now:
              </p>

              <div className="text-left inline-block mb-3">
                <span className="green-dot"></span>
                <span className="text-white mt-0 solution-text">
                  Actively search out and take the better work opportunities.
                </span>
              </div>
              <div className="text-left inline-block mb-3">
                <span className="green-dot"></span>
                <span className="text-white mt-0 solution-text">
                  Access job 'on the go' - he's more flexible in his time and in
                  control.
                </span>
              </div>
              <div className="text-left inline-block mb-3">
                <span className="green-dot"></span>
                <span className="text-white mt-0 solution-text">
                  Able to negotiate better deals directly with farmers,
                  restaurants and consumers, building trust.
                </span>
              </div>
            </FadeIn>
          </div>
        </div>
      </section> */}
      <section className="bg-secondary ">
        <div
          style={{ maxWidth: "1400px", margin: "0 auto" }}
          className=" p-10 lg:p-20 md:p-20"
        >
          <p className="partner-text col-span-3">
            partner <br /> <span className="text-white">organizations</span>
          </p>
          <div className="grid lg:grid-cols-3 md:grid-cols-3 gap-10 pt-10">
            <Partners
              name="Algorand Foundation"
              link="https://www.algorand.foundation/"
              image={Partner1}
            />
            <Partners
              name="Blockchain Supply Chain Association - Global"
              link="https://www.bsca.global/"
              image={Partner2}
            />
            <Partners
              name="LE 101"
              link="https://www.cm101innovation.fr"
              image={Partner4}
            />
            <Partners
              name="Hôtel Entreprises"
              link="https://www.cm101innovation.fr"
              image={Partner5}
            />
            <Partners
              name="Fabrique de Territoire"
              link="https://www.cm101innovation.fr/la-fabrique-de-territoire/"
              image={Partner6}
            />
            <Partners
              name="BC100+"
              link="https://bc100plus.org/"
              image={Partner7}
            />
            <Partners
              name="Unlimited Crypto"
              link="https://www.unlimit.com/"
              image={Partner8}
            />
            <Partners
              name="EURS by Stasis"
              link="https://eurs.stasis.net/"
              image={Partner9}
            />
            <Partners
              name="Quantoz Payments"
              link="https://quantozpay.com/"
              image={Partner11}
            />
            <Partners
              name="Le Village by CA"
              link="https://levillagebyca.com/en/"
              image={Partner12}
            />

            <div className="flex justify-center items-center">
              <a
                href="https://blockchainforgood.fr/"
                target="_blank"
                rel="noreferrer"
              >
                <div
                  style={{ height: "211px" }}
                  className="flex justify-center items-center"
                >
                  <img
                    src={Partner3}
                    alt=""
                    className=" "
                    style={{
                      width: "211px",
                      height: "auto",
                    }}
                  />
                </div>

                <p className="subtitle mt-3">Blockchain for Good</p>
              </a>
            </div>
          </div>
        </div>
      </section>
      {/* <iframe
        src="https://widgets.sociablekit.com/medium-publication-feed/iframe/25439264"
        frameborder="0"
        width="100%"
        height="1000"
      ></iframe> */}
      <div
        id="retainable-rss-embed"
        data-rss="https://medium.com/feed/retainable,
https://medium.com/feed/vue-mastery"
        data-maxcols="3"
        data-layout="grid"
        data-poststyle="inline"
        data-readmore="Read the rest"
        data-buttonclass="btn btn-primary"
        data-offset="-100"
      ></div>

      <section
        className="p-10 lg:p-20 md:p-20"
        style={{ maxWidth: "1400px", margin: "0 auto" }}
      >
        <p className="text-heading">
          the FAME <span className="text-primary">team</span>
        </p>
        <div className="grid lg:grid-cols-3 pt-10 gap-10">
          <Team
            person="Bilal Brahim"
            position="CEO"
            picture={Person1}
            content1="Bilal, a philosopher trained at the renowned Sorbonne University of Paris, has an impressive career spanning over fourteen years in the fields of ethics and metaphysics teaching in both France and Australia."
            content2="In 2019, Bilal came across the innovative work of Silvio Micali, sparking an interest in the transformative potential of Algorand technology for global financial inclusion. Seizing this opportunity, he embarked on an entrepreneurial journey with his project 'FAME', which aims to use Algorand's technology to enhance financial inclusion and social impact for smallholder farmers, while also promoting food transparency for consumers."
            content3="In addition to his project, Bilal holds a crucial role as the Commissioner for Food Security at the International Emergency Management Organisation, as established by the UN Treaty Series I-42766. This position reflects Bilal's unwavering commitment to addressing pressing global issues, particularly in food security."
            url="https://www.linkedin.com/in/bilal-brahim/"
          />
          <Team
            person="Stéphane Barroso"
            position="Tech Lead"
            picture={Person3}
            content1="An experienced software engineer with expertise spanning payments, industry, military, and finance, specializing in blockchain development. He is passionate about building high-quality, scalable, and secure applications."
            content2="Stéphane has worked with numerous corporations and has a strong passion for solving complex technical problems and delivering polished solutions. He firmly believes in the power of blockchain technology to revolutionize how we interact with commodities in an increasingly digital world."
            content3=""
            url="https://www.linkedin.com/in/stephanebarroso/"
          />

          <Team
            person="Valentijn Van Wezer"
            position="COO"
            picture={Person2}
            content1="Valentijn is an innovative leader with a deep commitment to sustainable agricultural and economic solutions in Africa. He and his family run a safari company in Tanzania, reflecting their passion for African wildlife and dedication to preserving natural ecosystems. Additionally, they are establishing various potato farming projects in Tanzania, Ghana and palm oil cultivation across Africa, showcasing their dedication to forward-thinking agricultural initiatives and community-based projects that align with the FAME mission of food transparency, financial inclusion, and sustainable practices."
            content2="With 25 years of experience at industry giants like BASF, Total, Esso, and Imerys Carbon Black, Valentijn blends strategic and altruistic approaches in his work. His expertise in these global companies has equipped him with deep analytical insight and a human-centric approach, enabling him to create efficient, market-leading solutions that drive economic empowerment and community development."
            content3="Valentijn's work is characterized by his ability to innovate and implement sustainable practices that benefit both local communities and the broader ecosystem. His leadership and vision align perfectly with FAME's mission to revolutionize the agricultural sector through the use of blockchain technology, promoting financial inclusion, and fostering decentralized governance. Valentijn's experience and dedication to sustainable development make him a valuable addition to the FAME leadership team."
            url="https://www.linkedin.com/in/valentijn-van-wezer-684474183/"
          />
          <Team
            person=" Christina Stelzl"
            position="Head of Supply Chain"
            picture={Person19}
            content1="Christina Stelzl is a seasoned professional specialized in business strategy and food supply chain management. With 20 years of experience in the food supply chain sector, her career began in logistics and retail business, transitioning later to significant roles in wholesale and retail companies in Austria and Germany."
            content2="In 2023, she founded her own trading and consulting firm based in Austria, offering expert consultation and project execution along the food supply chain to assist businesses in refining their purchasing or sales strategies and exploring new markets."
            content3="Christina earned her MBA in Management and Business Strategy from Hochschule für Angewandte Wissenschaften, Munich, with a thesis on sustainability labels in the German food industry, and a Bachelor of Arts in Business with a concentration in Logistics and Supply Chain Management from Fachhochschule des BFI, Vienna. Fluent in German and English, she excels in fostering strategic partnerships and brings expertise in ecological agriculture and supply chain management."
            content4=""
            url="https://www.linkedin.com/in/christina-stelzl-a455685a/"
          />
          <Team
            person="Judith"
            position="Community Manager in Kenya"
            picture={Person17}
            content1="My name is Judith from kenya and I am a Farmer,I am hard working and dedicated young farmer despite having a rare neurological condition(Myasthenia gravis)that doesn't stop me from the love I have for the land."
            content2="I was born and raised into a farming family and from a young age I was taught  the importance of taking care of the land and everything on it and I fell in love with the life of a Farmer which am doing upto now."
            content3=""
          />
          <Team
            person="Carlos Lima"
            position="FAME Ambassador for Portugal"
            picture={Person20}
            content1="Since 1999, Carlos Lima has taught Physics and Chemistry, earning his degree from the University of Minho in Braga, Portugal. He's spearheaded numerous initiatives to popularize science and has served as the vice-president of the Minho Scientific Astronomy Association since 2018."
            content2=" In addition to his academic pursuits, Carlos has been an advocate for blockchain technology since 2020, focusing especially on the Algorand project. As of August 2023, he is the FAME Ambassador for Portugal and Portuguese-speaking nations."
            content3=""
          />
          <Team
            person="Emmanuel Charles"
            position="Blockchain  Developer"
            picture={Person6}
            content1="Emmanuel Charles is a Certified Design Engineer and Software Developer with 4 years experience building crafted usable and scalable products in SAAP, SAAS, WEB2/WEB3, Mobile Solution and others  for companies to accelerate their growth."
            content2="Emmanuel's programming skills includes Java, Reach Blockchain, Dart(Flutter), Python(Automation) and JavaScript(Native) highly skilled in Design solution, Mobile app development , Data visualization, Blockchain development, Ethical hacking and Hardware Automation, currently building at ALGOFAME, he lives in Lagos, Nigeria."
            content3=""
            url="https://www.linkedin.com/in/emmanuelpcharles/"
          />
          <Team
            person="Abdulrazaq Oladapo"
            position="Full stack developer"
            picture={Person81}
            content1="Abdulrazaq is a Mechatronics Engineer and a senior software developer with more than 6 years experience and currently working alongside the executive team of ALGOFAME. "
            content2="Abdulrazaq specializes in mobile application development technology (Flutter) and is responsible for building FAME web3 farmers social solution and also an active enthusiast on ALGORAND blockchain."
            content3="Abdulrazaq has many computer programming skills which include Javascript(NodeJs), Dart(Flutter), C++(IOT) and Java(Native) and has built multiple applications on these platform languages. He currently lives in Nigeria, Ekiti State."
            url="https://www.linkedin.com/in/oladapo-abdulrazaq-6028a4206/"
          />
          <Team
            person="Aholu Samuel"
            position="Blockchain Developer"
            picture={Person9}
            content1="Samuel is a Tech-savvy Web Developer with over 4 years experience, who contributes specially in search engine optimization -  to launch custom-tailored code for websites presence, detailed understanding of hands-on approach to customizing web presence strategy. Resourceful navigation of intricate factors governing web planning, security, Hosting and SEO."
            content2="Samuel's programming skills includes CSS, JavaScript (Vanilla, React & Nodejs), PHP (Laravel), C++(IOT). He currently lives in Ekiti State, Nigeria."
            content3=""
            url="https://www.linkedin.com/in/samuel-aholu-b242711a3/"
          />
          <Team
            person="Chris Dawson"
            position="Graphic Designer"
            picture={Person10}
            content1="Chris runs his own branding and web-design studio in the UK - tallhat.com. With a degree in graphic design he worked for a large digital agency in London as 'Head of Design', producing creative work for brands such as Guinness, Peugeot and Microsoft. He is highly visual and ideas-led, working with organizations to help communicate their offerings to customers. He develops brands, marketing materials and designs websites for a wide variety of clients."
            content2="Chris discovered Algorand in early 2021 and has been exploring a few project ideas, recently launching SweeperKeeper - an unofficial World Cup NFT project - to test the water."
            content3=""
            url="https://www.linkedin.com/in/chrisdawson/"
          />

          {/* <Team
            person="Eyitayo Anjorin"
            position="Head of Nigeria"
            picture={Person11}
            content1="Eyitayo Anjorin is a seasoned professional with a background in the blockchain industry, including experience as a community manager, blockchain engineer, and blockchain advocate."
            content2="He has been active in the industry since 2016 and has a strong understanding of both the technical and business aspects of blockchain technology. In addition to his expertise in blockchain, Eyitayo is proficient in both writing and coding. He is a well-rounded and highly skilled individual who is dedicated to driving success within the blockchain industry."
            content3=""
          /> */}
        </div>
      </section>
      <section className=" bg-tertiary">
        <div
          className="p-10 lg:p-20 md:p-20"
          style={{ maxWidth: "1400px", margin: "0 auto" }}
        >
          <p className="text-heading text-white">
            the fame <span className="text-secondary">advisors</span>
          </p>
          <div className="grid lg:grid-cols-3 pt-10 gap-10 text-grey">
            <Team
              person="Liran Peretz"
              position="Agritech expert"
              picture={Person15}
              content1="He is the co-founder and CEO of Alty, the all-in-one platform powering the world’s best crypto communities."
              content2="Before founding Alty, he worked in the digital finance & gaming sectors and oversaw the finance department at Playtech and managed the development of skilled teams in Antigua and the Philippines. He is an early crypto adopter, entering the space in 2011. Over the past 11 years, he has been actively engaged in the cryptocurrency community as a supporter and investor.
              Beyond his interests in crypto and finance, he has deep roots in commercial agriculture. Prior to his crypto and tech roles, he was a prominent figure in the regional agriculture segment, known for introducing Israeli drip and fertigation technology systems across the Caribbean region."
              content3="He is passionate about community development projects, the implementation of new technologies, and kitesurfing."
            />
            {/* <Team
              person="Mokua (Erickson) Mogendi"
              position="Agritech expert"
              picture={Person16}
              content1="He believes technology can improve food security world wide, a generalist in Agricultural sector with all round skills in production in various enterprises within farming."
              content2="Livestock management,Crop management, Aquaculture management above all farm planning,budgeting and implementation.
              With a well resource mobilization and good structured agricultural policies, he believes we are able to feed ourselves without struggle."
              content3="Vertical farming, Aquaponics,Hydroponics,Greenhouse, among others can take us there."
            /> */}
            <Team
              person="Andrea Frosinini"
              position="Supply Chain expert"
              picture={Person14}
              content1="I am a Senior Trade Finance professional, who mainly held this position in corporate  contexts, with a profound passion for innovation and digitisation. I recently had the opportunity to look at my job under a futuristic perspective since, in January 2020, I was asked to join Hyperledger community as Chairperson of Hyperledger Trade Finance SIG with the aim at offering a contribution to the industry modernization and development of new products exploiting on blockchain and DLTs, more in general. So far, I have managed to create a sound, cohesive network of professionals and companies operating in the field of enterprise blockchain solutions for Digital Trade & Supply Chain Finance, that is now giving the rise to some interesting projects and PoCs, built on different blockchain networks."
              content2="In late september 2021, I was hired by XDC Foundation as Business Development Manager. In this position, I managed to expand the community of developers and also gave rise to a series of project under the umbrella of several international institutions such as ITFA, with its, DNI-Digital Negotiable Initiative and TFDI  and TradeFinance Distribution Initiative,  ICC Singapore DSI-Digital Standards Initiative and BAFT in the US."
              content3="This has so far allowed me to further develop my experience of matters related not only to trade finance instruments and procedures, but, most importantly, on how these are being impacted by current business dynamics and digital transformation, with an eye always set on the market trends and developments at global scale."
            />
            <Team person="MG" position="Technology Advisor" picture={Person5} />
          </div>
        </div>
        <div className="pt-10 pb-10"></div>
      </section>
      {/* <section>
        <Form />
      </section> */}
    </section>
  );
}
